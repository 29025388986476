import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Tabs.css'; 

const Tabs = () => {
  const [activeTab, setActiveTab] = useState('Main');
  const navigate = useNavigate();
  const location = useLocation(); 

  useEffect(() => {

    if (location.pathname === '/home') {
      setActiveTab('Main');
    } else if (location.pathname === '/economical-data') {
      setActiveTab('Economical Data');
    }
  }, [location.pathname]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    if (tab === 'Main') {
      navigate('/home');
    } else if (tab === 'Economical Data') {
      navigate('/economical-data');
    }
  };

  return (
    <div className="tabs-container">
      <div
        className={`tab ${activeTab === 'Main' ? 'active' : ''}`}
        onClick={() => handleTabClick('Main')}
      >
        Main
      </div>
      <div
        className={`tab ${activeTab === 'Economical Data' ? 'active' : ''}`}
        onClick={() => handleTabClick('Economical Data')}
      >
        Economical Data
      </div>
    </div>
  );
};

export default Tabs;
