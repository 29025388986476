import React, { useState, useEffect, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import companies from '../../companies.json';
import "./SearchBar.css";
import { FaSearch } from 'react-icons/fa';

export const SearchBar = () => {
    const [input, setInput] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const wrapperRef = useRef(null);
    const navigate = useNavigate();

    // update filtered data based on search input
    useEffect(() => {
        if (input === "") {
            setFilteredData([]);
            setShowResults(false);
        } else {
            const filtered = companies.filter(company =>
                company.symbol.toLowerCase().includes(input.toLowerCase()) ||
                company.name.toLowerCase().includes(input.toLowerCase())
            );
            setFilteredData(filtered);
            setShowResults(true);
        }
    }, [input]);

    // close dropdown when clicking outside
    useEffect(() => {
        function handleClickOutside(event) {
            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setShowResults(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef]);

    const handleSelectCompany = (company) => {
        setInput(`${company.name} (${company.symbol})`);
        setShowResults(false);
        navigate(`/company/${company.symbol}`, { state: { companyName: company.name } });    };

    return (
        <div className="input-wrapper" ref={wrapperRef}>
            <input
                type="text"
                placeholder="Search for a company..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onFocus={() => setShowResults(true)}
            />
            <FaSearch id='search-icon'/>
            {showResults && filteredData.length > 0 && (
                <ul className="results-list">
                {filteredData.map((company, index) => (
                    <li key={index} onClick={() => handleSelectCompany(company)}>
                        <span className="company-name">{company.name}</span>
                        <span className="company-details">{company.symbol} | CIK: {company.cik_str}</span>
                    </li>
                ))}
            </ul>            
            )}
        </div>
    );
}