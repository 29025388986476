import React from 'react';
import './CompanyHeader.css';
import { currencyFormat } from '../../utils/currencyFormat';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

function CompanyHeader({
  companyName,
  ticker,
  price,
  sector,
  industry,
  logo,
  marketCap,
  enterpriseValue,
  sharesOutstanding,
  employees,
  currency,
  website,
  onPriceDataClick // Accept the click handler prop
}) {
  return (
    <div className='company-header'>
      <div className="left-section">
        {logo ? (
          <img className="company-logo" src={logo} alt={`${companyName} logo`} />
        ) : (
          <div className="company-logo-loading">
            <LoadingSpinner />
          </div>
        )}
        <div className="company-details">
          <h1 className='company-name'>
          <a href={website} target="_blank" rel="noopener noreferrer" className="company-link">
            {companyName}
          </a>
          </h1>
          <div className="company-info">
            {ticker && <span className='company-ticker'>{ticker}</span>}
            {price && <span className='company-price'>${price} {currency}</span>}
          </div>
          <h3 className='company-sector-industry'>
            {sector} - {industry}
          </h3>
        </div>
      </div>

      <div className="right-section">
        <div className="company-stats">
          <div>
            <div><strong>Market Cap</strong>{currencyFormat(marketCap, true)}</div>
            <div><strong>Enterprise Value</strong>${currencyFormat(enterpriseValue)}</div>
          </div>
          <div>
            <div><strong>Shares Outstanding</strong>{currencyFormat(sharesOutstanding)}</div>
            <div><strong>Employees</strong>{currencyFormat(employees)}</div>
          </div>
        </div>
        <div className="company-actions">
          <button className="action-button" onClick={onPriceDataClick}>PRICE DATA</button> {/* Attach click handler */}
          <button className="action-button">MY REPORT</button>
        </div>
      </div>
    </div>
  );
}

export default CompanyHeader;
