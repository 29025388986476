import React from 'react';
import './LoadingSpinner.css';

function LoadingSpinner() {
  return (
    <div className='loading'>
        <div className="spinner"></div>
    </div>
  )
}

export default LoadingSpinner
