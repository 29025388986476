import React from 'react';
import "./Navbar.css"
import { SearchBar } from '../SearchBar/SearchBar';

function Navbar() {
  return (
    <>
    <nav className="navbar">
        <div className="navbar-container">
            <SearchBar id="search-bar" />
        </div>
        
    </nav>
      
    </>
  )
}

export default Navbar
