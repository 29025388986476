import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart, LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend } from 'chart.js';
import './GdpLineChart.css';

Chart.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);

const GdpLineChart = () => {
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const apiKey = 'B6126CF2-F4A0-4432-929A-BB84DDC9DF82';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apps.bea.gov/api/data/', {
          params: {
            UserID: apiKey,
            method: 'GetData',
            DataSetName: 'NIPA',
            TableName: 'T10101',
            Frequency: 'Q',
            Year: 'ALL',
            ResultFormat: 'json',
          },
        });

        const data = response.data?.BEAAPI?.Results?.Data;

        if (data && Array.isArray(data)) {
          const filteredData = data
            .filter((item) => item.LineDescription === 'Gross domestic product')
            .sort((a, b) => b.TimePeriod.localeCompare(a.TimePeriod))
            .slice(0, 80);

          const labels = filteredData.map((item) => item.TimePeriod).reverse();
          const values = filteredData.map((item) => parseFloat(item.DataValue)).reverse();

          setChartData({
            labels,
            datasets: [
              {
                label: 'GDP in %',
                data: values,
                fill: false,
                backgroundColor: '#78E4FF',
                borderColor: '#78E4FF',
                tension: 0.1,
              },
            ],
          });
        } else {
          setError('Data is not in expected format or is undefined');
        }
      } catch (error) {
        console.error('Error fetching BEA data:', error);
        setError('Failed to fetch data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="gdp-line-chart-container">
        <div className="gdp-line-chart-loading">
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="gdp-line-chart-container">
        <p className="gdp-line-chart-error">{error}</p>
      </div>
    );
  }

  if (!chartData) {
    return (
      <div className="gdp-line-chart-container">
        <p className="gdp-line-chart-error">No data available</p>
      </div>
    );
  }

  return (
    <div className="gdp-line-chart-container">
      <h3 className="gdp-line-chart-title">US Gross Domestic Product</h3>
      <p className="gdp-line-chart-subtitle">Numbers in %</p>
      <Line
        data={chartData}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Year and Quarter',
              },
            },
            y: {
              title: {
                display: true,
                text: 'GDP in %',
              },
            },
          },
        }}
      />
    </div>
  );
};

export default GdpLineChart;