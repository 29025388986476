import React, { useEffect, useState} from 'react';
import { formatNumber } from '../../utils/formatNumber';
import './FinancialStatementsData.css';

function FinancialStatementsData({ statement, financialData}) {
    const years = ['2006','2007','2008','2009','2010','2011','2012', '2013', '2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023'];
    const [accounts, setAccounts] = useState([]);
    const [roundingMode, setRoundingMode] = useState(1);     // 1 for units, 100 for hundreds, 1000 for thousands

    useEffect(() => {// Adjust the data depending on the selected statement type
        switch (statement) {
            case 'Income Statement':
                setAccounts([
                    "Total Revenue",
                    "Cost of Goods Sold",
                    "Gross Profit",
                    "Selling, General & Administrative Expenses",
                    "Research & Development Expenses",
                    "Operating Income",
                    "Interest Expense",
                    "Interest and Investment Income",
                    "Other Non-Operating Income",
                    "Earnings From Continuing Operations",
                    "Income Tax",
                    "Net Income",
                    "Basic Shares Outstanding (in shares)",
                    "Diluted Shares Outstanding (in shares)",
                    "EPS",

                ]);
                break;
            case 'Balance Sheet':
                    setAccounts([
                        // Assets
                        "Cash and Cash Equivalents",
                        "Marketable Securities",
                        "Accounts Receivable",
                        "Other non-trade Receivables",
                        "Inventory",
                        "Prepaid Expenses",
                        "Other Current Assets",
                        "Total Current Assets",
                        "Non-current Marketable Securities",
                        "Property, Plant and Equipment",
                        "Intangible Assets",
                        "Goodwill",
                        "Long-term Investments",
                        "Other Non-current Assets",
                        "Total Non-current Assets",
                
                        "Total Assets",
                
                        // Liabilities
                        "Accounts Payable",
                        "Short-term Debt",
                        "Current Portion of Long-term Debt",
                        "Accrued Expenses",
                        "Deferred Revenue",
                        "Other Current Liabilities",
                        "Total Current Liabilities",
                
                        "Long-term Debt",
                        "Other Non-current Liabilities",
                        "Total Non-current Liabilities",
                
                        "Total Liabilities",
                
                        // Shareholders' Equity
                        "Common Stock Value and Additional Paid-in Capital",
                        "Retained Earnings or Defecit",
                        "Treasury Stock",
                        "Accumulated Other Comprehensive Income",
                        "Total Shareholders' Equity",
                
                        "Total Liabilities and Shareholders' Equity"
                    ]);
                break;
            case 'Cash Flow':
                    setAccounts([
                        // Cash Flow from Operating Activities
                        "Depreciation and amortization",
                        "Share-based compensation expense",
                        "Other",
                        "Accounts receivable, net",
                        "Vendor non-trade receivables",
                        "Inventories",
                        "Other current and non-current assets",
                        "Change in Accounts payable",
                        "Other current and non-current liabilities",
                        "Cash generated by operating activities",
                
                        // Cash Flow from Investing Activities
                        "Purchases of marketable securities",
                        "Proceeds from maturities of marketable securities",
                        "Proceeds from sales of marketable securities",
                        "Payments for acquisition of property, plant and equipment",
                        "Other",
                        "Cash generated by/(used in) investing activities",
                
                        // Cash Flow from Financing Activities
                        "Payments for taxes related to net share settlement of equity awards",
                        "Payments for dividends and dividend equivalents",
                        "Repurchases of common stock",
                        "Proceeds from issuance of term debt, net",
                        "Repayments of term debt",
                        "Proceeds from/(Repayments of) commercial paper, net",
                        "Other",
                        "Cash used in financing activities",
                
                        // Summary
                        "Increase/(Decrease) in cash, cash equivalents and restricted cash",
                        "Cash, cash equivalents and restricted cash, ending balances",
                        
                        // Supplemental Disclosures
                        "Cash paid for income taxes, net",
                        "Cash paid for interest"
                    ]);
                break;
            default:
                break;
        }

    }, [statement]);


    // Create a grid to display the financial data in rows and columns
    const createGrid = () => {
        let grid = Array(accounts.length).fill(null).map(() => Array(years.length + 1).fill("-"));
          
        const filteredData = financialData.filter(item => item.statement === statement);
        const sortedData = filteredData.sort((a, b) => b.date_index - a.date_index);
    
        accounts.forEach((account, index) => {
          grid[index][0] = account;
        });
    
        sortedData.forEach(item => {
          const { value, label_index, date_index, depth } = item;
          if (depth === 1 && label_index - 1 < accounts.length && date_index < years.length) {
            if (grid[label_index - 1][date_index + 1] === "-") {
              grid[label_index - 1][date_index + 1] = value;
            }
          }
        });
    
        return grid;
      };
    
      const grid = createGrid();

    // Bold these accounts
    const boldAccounts = ["Total Revenue", "Gross Profit", "Operating Income", "Net Income", "EPS", "Total Current Assets", "Total Assets", "Total Current Liabilities", "Total Liabilities", "Total Shareholders' Equity", "Total Liabilities and Shareholders' Equity", "Cash generated by operating activities", "Cash generated by/(used in) investing activities", "Cash used in financing activities"];

    return (
        <div className="financial-statements-container">
            <div className="financial-statements-controls">
                <div className="rounding-buttons">
                    <button
                        className={roundingMode === 1 ? 'active' : ''}
                        onClick={() => setRoundingMode(1)}
                    >
                        Units
                    </button>
                    <button
                        className={roundingMode === 100 ? 'active' : ''}
                        onClick={() => setRoundingMode(100)}
                    >
                        Hundreds
                    </button>
                    <button
                        className={roundingMode === 1000 ? 'active' : ''}
                        onClick={() => setRoundingMode(1000)}
                    >
                        Thousands
                    </button>
                    <button
                        className={roundingMode === 1000000 ? 'active' : ''}
                        onClick={() => setRoundingMode(1000000)}
                    >
                        Millions
                    </button>
                </div>
            </div>
                <div className="financial-statements-table-wrapper">
                    <table className="financial-statements-table">
                        <thead>
                            <tr>
                                <th className="sticky-column sticky-header sticky-statement">{statement}</th>
                                {years.map((year, index) => (
                                    <th key={index} className="sticky-header">{year}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                        {grid.map((row, rowIndex) => {
                            const isBold = boldAccounts.includes(row[0]);
                            return (
                                <tr key={rowIndex}>
                                    {row.map((cell, colIndex) => (
                                        colIndex === 0 ? (
                                            <td key={colIndex} className={`sticky-column ${isBold ? 'bold-cell' : ''}`}>
                                                {cell}
                                            </td>
                                        ) : (
                                            <td key={colIndex} className={isBold ? 'bold-cell' : ''}>
                                                {formatNumber(cell, row[0] === 'EPS', roundingMode)}
                                            </td>
                                        )
                                    ))}
                                </tr>
                            );
                        })}
                    </tbody>
                    </table>
                </div>
        </div>
    );
}

export default FinancialStatementsData;