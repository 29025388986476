import axios from 'axios';

export const fetchStockData = async (symbol) => {
  try {
    const response = await axios.get(`https://api.finalyst.xyz/stock-data/${symbol}`);
    return response.data; // axios returns response data directly
  } catch (error) {
    console.error('Error fetching stock data:', error);
    throw error;
  }
};
