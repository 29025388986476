import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const formatProductsText = (text) => {
    const sections = text
      .split(/(\*\*[^*]+\*\*)/)
      .filter(Boolean);
    return sections.map((section, index) => {
      if (section.startsWith('**') && section.endsWith('**')) {
        const title = section.slice(2, -2);
        return (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle1">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body1">
                {sections[index + 1].replace('/n/n', '') || ''}
              </Typography>
            </AccordionDetails>
          </Accordion>
        );
      }
      return null;
    });
  };