import axios from 'axios';

export const fetchRiskFactors = async (symbol, userEmail) => {
  try {
    const response = await axios.get(`https://api.finalyst.xyz/risk_factors/${symbol}`, {
      params: { user_email: userEmail }  // This is valid in axios
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching risk factors:', error);
    throw error;
  }
};
