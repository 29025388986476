import React from 'react';
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { currencyFormat } from '../../utils/currencyFormat';
import "./OfficersInformation.css";

export const renderOfficers = ({ officers }) => {
    if (!officers || officers.length === 0) {
        return <Typography variant="body1">No officers data available.</Typography>;
    }
    return (
        <div className='officers-container'>
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Age</TableCell>
                        <TableCell>Fiscal Year</TableCell>
                        <TableCell>Total Pay</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {officers.map((officer, index) => (
                        <TableRow key={index}>
                            <TableCell>{officer.name}</TableCell>
                            <TableCell>{officer.title}</TableCell>
                            <TableCell>{officer.age}</TableCell>
                            <TableCell>{officer.fiscalYear}</TableCell>
                            <TableCell>{currencyFormat(officer.totalPay, true)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    );
};
