import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';
import { SidebarData } from './SidebarData';
import { LogoutOutlined } from '@mui/icons-material';

function Sidebar({ onSignOut }) {
  const handleSignOut = (e) => {
    e.preventDefault();
    if (onSignOut && typeof onSignOut === 'function') {
      onSignOut();
    }
  };

  return (
    <div className='sidebar'>
      <img className="logo" src="../assets/finalystLogo/Finalyst-logo-250x56.png" alt="Finalyst Logo" />
      <ul className='list'>
        {SidebarData.map((val, key) => {
          return (
            <li key={key} className='row'>
              <NavLink 
                to={val.path} 
                className='row-link' 
              >
                <div id='icon'>{val.icon}</div>
                <div id='title'>{val.title}</div>
              </NavLink>
            </li>
          );
        })}
      </ul>
      
      <div className="sign-out-container">
        <button onClick={handleSignOut} className="sign-out-button">
          <div id='icon'><LogoutOutlined /></div>
          <div id='title'>Sign Out</div>
        </button>
      </div>
    </div>
  );
}

export default Sidebar;