import React from 'react';
import '../FavoritesTable/FavoritesTable.css';

const EquitiesTable = () => {
    // placeholder with sample data for equities
    const equitiesData = [
        { company: 'Microsoft', marketCap: 4321, change: '+84%' },
        { company: 'NVIDIA', marketCap: 4033, change: '-8%' },
        { company: 'Apple', marketCap: 3128, change: '+2%' },
        { company: 'Alphabet', marketCap: 2104, change: '+33%' },
        { company: 'Amazon', marketCap: 2003, change: '+30%' },
        { company: 'Meta Platform', marketCap: 1894, change: '+15%' },
        { company: 'Berkshire Hathaway', marketCap: 405, change: '-12%' },
    ];

    return (
        <div className="favorites-section">
            <h3>Equities</h3>
            <table className="favorites-table">
                <thead>
                    <tr>
                        <th>Company</th>
                        <th>Market Cap</th>
                        <th>Change</th>
                    </tr>
                </thead>
                <tbody>
                    {equitiesData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.company}</td>
                            <td>{item.marketCap}</td>
                            <td>{item.change}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default EquitiesTable;
