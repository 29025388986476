import React from 'react';
import '../FavoritesTable/FavoritesTable.css';

const IndustryTable = () => {
    // placeholder with sample data for industries
    const industryData = [
        { industry: 'Automotive', size: 4321, change: '+84%' },
        { industry: 'Computer and Electronics', size: 4033, change: '-8%' },
        { industry: 'Healthcare', size: 3128, change: '+2%' },
    ];

    return (
        <div className="favorites-section"> 
            <h3>Industries</h3>
            <table className="favorites-table"> 
                <thead>
                    <tr>
                        <th>Industry</th>
                        <th>Size</th>
                        <th>Change</th>
                    </tr>
                </thead>
                <tbody>
                    {industryData.map((item, index) => (
                        <tr key={index}>
                            <td>{item.industry}</td>
                            <td>{item.size}</td>
                            <td>{item.change}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default IndustryTable;
