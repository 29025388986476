import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './GdpIndustryTable.css';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const GdpIndustryTable = () => {
  const [industryData, setIndustryData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [searchTerm, setSearchTerm] = useState('');
  const apiKey = 'B6126CF2-F4A0-4432-929A-BB84DDC9DF82';

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apps.bea.gov/api/data/', {
          params: {
            UserID: apiKey,
            method: 'GetData',
            DataSetName: 'GDPbyIndustry',
            Year: '2023',
            TableID: '1',
            Industry: 'ALL',
            Frequency: 'Q',
            ResultFormat: 'json',
          },
        });

        const data = response.data?.BEAAPI?.Results[0]?.Data;
        console.log(data);

        if (data && Array.isArray(data)) {
          const formattedData = {};

          data.forEach(item => {
            const industryDescription = item.IndustrYDescription || 'Unknown Industry';
            const quarter = item.Quarter;
            const gdpValue = parseFloat(item.DataValue) || 0;

            if (!formattedData[industryDescription]) {
              formattedData[industryDescription] = { q1: 0, q2: 0, q3: 0, q4: 0 };
            }

            if (quarter === 'I') formattedData[industryDescription].q1 = gdpValue;
            if (quarter === 'II') formattedData[industryDescription].q2 = gdpValue;
            if (quarter === 'III') formattedData[industryDescription].q3 = gdpValue;
            if (quarter === 'IV') formattedData[industryDescription].q4 = gdpValue;
          });

          const tableData = Object.keys(formattedData).map(industry => ({
            industry,
            ...formattedData[industry],
          }));

          setIndustryData(tableData);
        } else {
          console.error('Data format error:', response.data);
        }

        setLoading(false);
      } catch (error) {
        console.error('Error fetching BEA data:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const sortData = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = () => {
    const sortableData = [...industryData];
    if (sortConfig.key !== null) {
      sortableData.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableData;
  };

  const getFilteredData = () => {
    return getSortedData().filter(row =>
      row.industry.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  return (
    <div className="gdp-industry-table">
      <h3>Gross Output by Industry</h3>
      <p>numbers in millions</p>
      <input
        type="text"
        placeholder="Search industries..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <table>
          <thead>
            <tr>
              <th onClick={() => sortData('industry')}>Industries</th>
              <th onClick={() => sortData('q1')}>Q1</th>
              <th onClick={() => sortData('q2')}>Q2</th>
              <th onClick={() => sortData('q3')}>Q3</th>
              <th onClick={() => sortData('q4')}>Q4</th>
            </tr>
          </thead>
          <tbody>
            {getFilteredData().map((row, index) => (
              <tr key={index}>
                <td>{row.industry}</td>
                <td>{row.q1}</td>
                <td>{row.q2}</td>
                <td>{row.q3}</td>
                <td>{row.q4}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default GdpIndustryTable;