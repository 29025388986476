import React, { useEffect, useState } from 'react';
import { fetchFilings } from '../../api'; // Import the API function

import './FilingsBoxes.css';

function FilingsBoxes({ symbol }) {
  const [filings, setFilings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchFilings(symbol);
        setFilings(data);
      } catch (error) {
        console.error('Error fetching filings data:', error);
      }
    };

    fetchData();
  }, [symbol]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const renderFilingsByCategory = (formType) => {
    return filings
      .filter(filing => filing.form.includes(formType))
      .map((filing, index) => (
        <div key={index} className="filing-item">
          <a href={filing.filing_url} target="_blank" rel="noopener noreferrer">
            {filing.form}
          </a>
          <span className="filing-date">{formatDate(filing.filing_date)}</span>
        </div>
      ));
  };

  return (
    <div className="filings-boxes">
      <div className="filings-category">
        <h3>Annual Reports</h3>
        <div className="filings-list">
          {renderFilingsByCategory('10-K')}
        </div>
      </div>
      <div className="filings-category">
        <h3>Quarterly Reports</h3>
        <div className="filings-list">
          {renderFilingsByCategory('10-Q')}
        </div>
      </div>
      <div className="filings-category">
        <h3>Proxy Statements</h3>
        <div className="filings-list">
          {renderFilingsByCategory('DEF 14A')}
        </div>
      </div>
      <div className="filings-category">
        <h3>Press Releases</h3>
        <div className="filings-list">
          {renderFilingsByCategory('8-K')}
        </div>
      </div>
    </div>
  );
}

export default FilingsBoxes;