import React from "react";
import { Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import './HoldersInformation.css';

export const renderOwners = ({ holders }) => {
    if (!holders || holders.length === 0) {
        return <Typography variant="body1">No institutional holders data available.</Typography>;
    }
    return (
        <div className="holders-container">
        <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Date Reported</TableCell>
                        <TableCell>Holder</TableCell>
                        <TableCell>Percentage Held</TableCell>
                        <TableCell>Shares</TableCell>
                        <TableCell>Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {holders.map((holder, index) => (
                        <TableRow key={index}>
                            <TableCell>{holder.dateReported}</TableCell>
                            <TableCell>{holder.holder}</TableCell>
                            <TableCell>{(holder.pctHeld * 100).toFixed(2)}%</TableCell>
                            <TableCell>{holder.shares.toLocaleString()}</TableCell>
                            <TableCell>${holder.value.toLocaleString()}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        </div>
    );
};
