import axios from 'axios';

export const fetchFilings = async (symbol) => {
    try {
        // Use axios to make the GET request
        const response = await axios.get(`https://api.finalyst.xyz/filings/${symbol}`);
        return response.data; // Axios returns the data in the response object
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
}