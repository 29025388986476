import axios from 'axios';

export const getFinancialData = async (symbol, userEmail, setLoading, setFinancialData, setError) => {
  setLoading(true);
  try {
    //console.log(`Sending request for ${symbol} with email: ${userEmail}`);
    const response = await axios.get(`https://api.finalyst.xyz/financial-data/${symbol}`, {
      params: { user_email: userEmail }  // This is valid in axios
    });
    setFinancialData(response.data); // Set the data directly from response
    setError(null);
  } catch (error) {
    console.error('Error fetching financial data:', error);
    if (error.response) {
      // Handle error response
      setError(`Error: ${error.response.status} - ${error.response.statusText}`);
    } else if (error.request) {
      // No response received from the server
      setError("No response received from the server.");
    } else {
      // Other errors
      setError(error.message);
    }
  } finally {
    setLoading(false);
  }
};
