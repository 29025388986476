import React, { useState } from 'react';
import FinancialStatementsData from '../FinancialStatementsData/FinancialStatementsData';
import './FinancialStatementsTab.css';

function FinancialStatementsTab({ financialData }) {

    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabClick = (index) => {
        setSelectedTab(index);
    };

    const renderTabContent = () => {
        switch (selectedTab) {
            case 0:
                return <FinancialStatementsData statement="Income Statement" financialData={financialData} />;
            case 1:
                return <FinancialStatementsData statement="Balance Sheet" financialData={financialData} />;
            case 2:
                return <FinancialStatementsData statement="Cash Flow" financialData={financialData}/>;
            default:
                return null;
        }
    };

    return (
        <div className='financial-statements-tab'>
            <div className='tabs-container'>
                <div className={`tab ${selectedTab === 0 ? 'active' : ''}`} onClick={() => handleTabClick(0)}>Income Statement</div>
                <div className={`tab ${selectedTab === 1 ? 'active' : ''}`} onClick={() => handleTabClick(1)}>Balance Sheet</div>
                <div className={`tab ${selectedTab === 2 ? 'active' : ''}`} onClick={() => handleTabClick(2)}>Cash Flow Statement</div>
            </div>
            <div className="tab-content">
                {renderTabContent()}
            </div>
        </div>
    );
}

export default FinancialStatementsTab;
