import React from 'react';
import { Typography, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import riskfactors from '../../riskfactors.json';

export const renderLocalRiskFactors = (selectedCompany) => {
    const risksFromLocal = riskfactors?.[selectedCompany?.symbol];
    if (!risksFromLocal || !risksFromLocal.filings) {
        return null;  // Return null when no local data is found, allowing fallback to fetched data
    }

    return risksFromLocal.filings.map((filing, index) => (
        <Box key={index} sx={{ marginBottom: 2 }}>
            {filing.entries && filing.entries.map((entry, idx) => (
                <Box key={idx}>
                    {entry.subentries && entry.subentries.map((subentry, subidx) => (
                        <Accordion key={subidx}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography>{subentry.riskfactor}</Typography>
                            </AccordionSummary>
                            {subentry.subentries && subentry.subentries.map((nestedSubentry, nestedSubidx) => (
                                <AccordionDetails key={nestedSubidx}>
                                    <Typography>{nestedSubentry.riskfactor}</Typography>
                                </AccordionDetails>
                            ))}
                        </Accordion>
                    ))}
                </Box>
            ))}
        </Box>
    ));
};
