import React, { useEffect, useState } from 'react';
import companies from '../../companies.json';
import './CompanyData.css';
import { formatProfileText } from '../../utils/formatProfileText';
import { formatProductsText } from '../../utils/formatProductsText';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { renderLocalRiskFactors } from '../LocalRiskDataProcessor/LocalRiskDataProccesor';
import FinancialStatementsData from '../FinancialStatementsData/FinancialStatementsData';
import FilingsBoxes from '../FilingsBoxes/FilingsBoxes';
import { renderOfficers } from '../OfficersInformation/OfficersInformation';
import { renderOwners } from '../HoldersInformation/HoldersInformation';

export function BusinessProfile({ symbol, summary }) {
    const [profileData, setProfileData] = useState('');

    useEffect(() => {
        const company = companies.find(c => c.symbol === symbol);
        setProfileData(company ? (company.profile.trim() !== '' ? formatProfileText(company.profile) : <div className='data-render'>{summary}</div>) : 'No data available' ||  <div className='data-render'>{summary}</div> );
    }, [symbol, summary]);

    return (
        <div>
            <p>{profileData}</p>
        </div>
    );
}

export function ProductsServices({ symbol }) {
    const [productsData, setProductsData] = useState('');

    useEffect(() => {
        const company = companies.find(c => c.symbol === symbol);
        setProductsData(company ? formatProductsText(company.products): 'No data available');
    }, [symbol]);

    return (
        <div>
            <p>{productsData}</p>
        </div>
    );
}

export function Risks({ symbol, risks }) {
    const [riskData, setRiskData] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const company = companies.find(c => c.symbol === symbol);
        setLoading(true);
        setTimeout(() => {
            const renderedRiskFactors = renderLocalRiskFactors(company) || null;  // Check if local data is available
            if (renderedRiskFactors) {
                setRiskData(renderedRiskFactors);
            } else if (risks) {
                // Directly render risks as they are
                setRiskData(
                    <div className='data-render'>
                        <h4>Risk Factors:</h4>
                        <p>{risks}</p>
                    </div>
                );
            } else {
                setRiskData('No risk factors available');
            }
            setLoading(false);
        }, 500);
    }, [symbol, risks]);

    return (
        <div>
            {loading ? <LoadingSpinner /> : <div className='risks-render'>{riskData}</div>}
        </div>
    );
}

export function FinancialStatements() {
    return (
        <div>
            <p><FinancialStatementsData /></p>
        </div>
    );
}


export function Holders({ symbol, holders }) {
    const [holdersData, setHoldersData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            if (holders && holders.length > 0) {
                setHoldersData(renderOwners({ holders }));
            } else {
                setHoldersData('No institutional holders data available');
            }
            setLoading(false);
        }, 500);
    }, [symbol, holders]);

    return (
        <div>
            {loading ? <LoadingSpinner /> : holdersData}
        </div>
    );
}


export function Officers({ symbol, officers }) {
    const [officersData, setOfficersData] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            if (officers && officers.length > 0) {
                setOfficersData(renderOfficers({ officers }));
            } else {
                setOfficersData('No officers data available');
            }
            setLoading(false);
        }, 500);
    }, [symbol, officers]);

    return (
        <div>
            {loading ? <LoadingSpinner /> : officersData}
        </div>
    );
}


export function Filings({ symbol }) {
    return (
        <div>
            <p><FilingsBoxes symbol={symbol} /></p>
        </div>
    );
}