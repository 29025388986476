import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { parseISO } from 'date-fns';
import './PriceChart.css';

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function PriceChart({ priceInfo }) {
    console.log('PriceChart priceInfo:', priceInfo);
  
    if (!priceInfo || !priceInfo.date_price_dict) {
      return <div>No price data available</div>;
    }
  
    const datePriceDict = priceInfo.date_price_dict;
    const chartData = {
        datasets: [
          {
            label: 'Price',
            data: Object.entries(datePriceDict).map(([date, price]) => ({
              x: parseISO(date),
              y: price,
            })),
            borderColor: 'rgba(72, 192, 255, 0.7)',  // Light blue line
            backgroundColor: 'rgba(72, 192, 255, 0.1)', // Light blue area under the curve (if needed)
            borderWidth: 2,  // Line thickness
            tension: 0.5,  // Higher tension for smoother lines
            cubicInterpolationMode: 'monotone',  // Use monotone interpolation for smooth curves
            pointRadius: 0,  // Remove points
            pointHoverRadius: 0,  // No hover effect on points
            fill: false,  // Disable filling under the line
          },
        ],
      };
      
      const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'day',
              tooltipFormat: 'MMM d',  // Shorter date format
            },
            title: {
              display: false,  // No title on the x-axis
            },
            grid: {
              display: false,  // Remove x-axis grid lines
            },
            ticks: {
              font: {
                size: 12,
              },
              color: '#666',  // Gray color for labels
            },
          },
          y: {
            beginAtZero: false,
            title: {
              display: false,  // No title on the y-axis
            },
            grid: {
              borderDash: [5, 5],  // Dashed grid lines on y-axis
              color: '#e0e0e0',  // Light gray grid color
            },
            ticks: {
              callback: (value) => `$${value.toFixed(2)}`,  // Format numbers with decimals
              font: {
                size: 12,
              },
              color: '#666',  // Gray color for labels
            },
          },
        },
        plugins: {
          legend: {
            display: false,  // No legend
          },
          tooltip: {
            backgroundColor: '#fff',
            titleFont: { size: 14 },
            titleColor: '#333',
            bodyFont: { size: 12 },
            bodyColor: '#333',
            borderColor: '#e0e0e0',
            borderWidth: 1,
            callbacks: {
              label: (context) => `Price: $${context.parsed.y.toFixed(2)}`,
            },
          },
        },
      };
      
        
    return (
      <div className="price-chart-container">
        <Line data={chartData} options={options} />
      </div>
    );
}

export default PriceChart;
