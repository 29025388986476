import React from 'react'
import FavoritesTable from '../components/Tables/FavoritesTable/FavoritesTable'
import IndustryTable from '../components/Tables/IndustryTable/IndustryTable'
import EquitiesTable from '../components/Tables/EquitiesTable/EquitiesTable';
import GdpOutputChart from '../components/GdpData/GdpOutputChart';
import Tabs from '../components/Tabs/Tabs';

const Home = () => {
    return (
        <div className="home">
            <Tabs />
            <div className="table-container">
                <FavoritesTable />
                <IndustryTable />
            </div>
            <div className="table-container"> 
            <EquitiesTable />
            <GdpOutputChart />
            </div>
        </div>
    );
};

export default Home
