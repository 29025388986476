import React from 'react';
import { GdpLineChart, GdpOutputChart, GdpOutputBarChart, GdpIndustryTable } from '../components/GdpData';
import Tabs from '../components/Tabs/Tabs';

function EconomicalData() {
  return (
    <div>
      <Tabs />
      <GdpLineChart />
      <div className="chart-container">
        <GdpOutputChart />
        <GdpOutputBarChart />
      </div>
      <GdpIndustryTable />
    </div>
  );
}

export default EconomicalData;
